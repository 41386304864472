<template>
  <div class="coupon-container">
      <div class="coupon-box">
          <!-- <div class="coupon-box-top">我的优惠券</div> -->
          <div class="coupon-box-top-detail">
              <div class="coupon-box-top-detail-wrap">
                <el-tabs v-model="use">
                    <el-tab-pane  label="未使用" name="first">
                        <div class="coupon-box-div">
                            <div class="cupon-box-juan" :key="k" v-for="(v,k) in arr1">
                                <div class="cupon-box-juan-div1">
                                    <div>
                                        <span>¥</span>{{v.discount_amount}}
                                    </div>
                                    <div>满{{v.satisfy_amount}}元可用</div>
                                </div>
                                <div class="cupon-box-juan-div2">
                                    <div>{{v.couponTitle}}</div>
                                    <div>{{v.validDateBegin}}~{{v.validDateEnd}}</div>
                                </div>
                                <div class="cupon-box-juan-div3">
                                    <div class="cupon-box-juan-div3-rule" @click="showRule(v.useWay.use_way_goods,v.instructions)">使用规则</div>
                                    <div class="cupon-box-juan-div3-use" @click="couponUse">立即使用</div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="已使用" name="second">
                        <div class="coupon-box-div">
                            <div class="cupon-box-juan juan2" :key="k" v-for="(v,k) in arr1">
                                <div class="cupon-box-juan-div1">
                                    <div>
                                        <span>¥</span>{{v.discount_amount}}
                                    </div>
                                    <div>满{{v.satisfy_amount}}元可用</div>
                                </div>
                                <div class="cupon-box-juan-div2">
                                    <div>{{v.couponTitle}}</div>
                                    <div>{{v.validDateBegin}}~{{v.validDateEnd}}</div>
                                </div>
                                <div class="cupon-box-juan-div3">
                                    <div class="cupon-box-juan-div3-rule">使用规则</div>
                                    <div class="cupon-box-juan-div3-use use2">立即使用</div>
                                </div>
                            </div>
                        </div>
                        </el-tab-pane>

                        <el-tab-pane label="已过期" name="third">
                        <div class="coupon-box-div">
                            <div class="cupon-box-juan juan3" :key="k" v-for="(v,k) in arr1">
                                <div class="cupon-box-juan-div1 dateTime">
                                    <div>
                                        <span>¥</span>{{v.discount_amount}}
                                    </div>
                                    <div>满{{v.satisfy_amount}}元可用</div>
                                </div>
                                <div class="cupon-box-juan-div2">
                                    <div>{{v.couponTitle}}</div>
                                    <div>{{v.validDateBegin}}~{{v.validDateEnd}}</div>
                                </div>
                                <div class="cupon-box-juan-div3">
                                    <div class="cupon-box-juan-div3-rule">使用规则</div>
                                    <div class="cupon-box-juan-div3-use use2">立即使用</div>
                                </div>
                            </div>
                        </div>
                        </el-tab-pane>
                </el-tabs>
                <!-- <div class="coupon-block">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage1"
                      :page-size="pageSize"
                      layout="prev, pager, next"
                      :total="total">
                    </el-pagination>
                </div> -->
              </div>
          </div>
      </div>
    <div style="clear:both;"></div>
    <div class="coupon-mask" v-show="couponShow">
        <div>
            <img :src="require('/static/images/orderdetailcha.png')" alt="" @click="couponClose">
            <span class="mask1">使用规则</span>
            <div>
                <!-- <div>使用业务：{{risk}}</div> -->
                <div>使用说明：{{rule}}</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'coupon',
  data(){
      return{
          use:"first",
          arr1:[{

          }],
          time:'',
          type:1,
          total:0,
        currentPage1: 1,
        pageSize: 5,
        couponShow:false,
        risk:'',
        rule:''
      }
  },
  components: {
  },
  watch:{
      'use'(newValue){
          if(newValue == "first"){
              this.getData();
          }
          if(newValue == "second"){
              this.getData();
          }
          if(newValue == "third"){
              this.getData();
          }
      },
  },
  methods:{
    showRule(val1,val2){
        this.couponShow = true;
    // console.log(val1)
        this.risk = val1;
        this.rule = val2;
    },
    couponClose(){
        this.couponShow = false
    },
      getData(){
          if(this.use == "first"){
              this.type = 1;
          }
          if(this.use == "second"){
              this.type = 2;
          }
          if(this.use == "third"){
              this.type = 3;
          }
          this.$http.post(this.GLOBAL.new_url+'/coupon/queryAllCoupon',{
                userCode:this.$cookie.getCookie('u_id'),
                userCouponStatus:this.type,
                // pageSize:1000,
                // pageNum:1
            }).then(res=>{
                if(res.data.code == "200"){
                    // console.log(res)
                    this.arr1 = res.data.data;
                    if(this.arr1.length>0){
                        this.arr1.map(x=>{
                        x.validDateBegin = x.validDateBegin.slice(0, x.validDateBegin.indexOf(' '));
                        x.validDateEnd = x.validDateEnd.slice(0, x.validDateEnd.indexOf(' '));
                        x.useWay = JSON.parse(x.useWay)
                        x.discount_amount = x.useWay.discount_amount;
                        x.satisfy_amount = x.useWay.satisfy_amount;
                    })
                    // console.log(this.arr1)
                }
                    
                }
            })
      },
    //   handleSizeChange(val) {
    //     this.pageSize = val;
    //     this.getData();
    //   },

    // handleCurrentChange(val) {
    //     this.currentPage1 = val;
    //     this.getData();
    // },
    couponUse(){
        this.$router.push('/personal/buyCart')
    }
  },
  mounted(){
      this.getData();
  }
}

</script>

<style scoped>
    .coupon-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        min-height: 950px;
        background-color: #fff;
        padding-top: 32px;
        box-sizing: border-box;
    }
    .coupon-box-top{
      font-family: "PingFangSC";
      font-size: 22px;
      font-weight: 100;
      color: #999999;
      margin: 0 0 14px 15px;
    }
    .coupon-box-top-detail{
      width: 850px;
      margin: 0 auto;
    }
    .coupon-box-top-detail-wrap{
        width: 850px;
    }
    .cupon-box-juan{
        width: 850px;
        height: 160px;
        background: url("../../../../static/images/packet.png") 100% no-repeat;
        background-size: cover;
        margin-bottom: 20px;
        position: relative;
    }
    .cupon-box-juan>div{
        position: absolute;
    }
    .coupon-box-div{
        height: 940px;
    }
    .cupon-box-juan-div1{
       top:27px;
       left: 18px;
       color: #FF5E00;
    }
    .cupon-box-juan-div1>div:nth-of-type(1){
        font-size: 40px;
       margin-bottom: 10px;
    }
    .cupon-box-juan-div1>div:nth-of-type(2){
        font-size: 18px;
    }
    .cupon-box-juan-div1>div>span{
        font-size: 18px;
        margin-right: 5px;
    }
    .cupon-box-juan-div2{
        top:17px;
        left: 162px;
    }
    .cupon-box-juan-div2>div:nth-of-type(1){
        font-size: 20px;
        margin-bottom: 70px;
    }
    .cupon-box-juan-div2>div:nth-of-type(2){
        color: #8B8B8B;
        font-size: 14px;
    }
    .cupon-box-juan-div3{
        top:17px;
        right: 48px;
    }
    .cupon-box-juan-div3-rule{
        font-size: 20px;
        margin-bottom: 58px;
        cursor: pointer;
    }
    .cupon-box-juan-div3-use{
        width: 90px;
        height: 32px;
        background: #FF5E00;
        border-radius: 17px;
        cursor: pointer;
        line-height: 32px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .juan2{
        background: url("../../../../static/images/packet2.png") 100% no-repeat;
        background-size: cover;
    }
    .juan3{
        background: url("../../../../static/images/packet3.png") 100% no-repeat;
        background-size: cover;
    }
    .use2{
        background: #666666;
    }
    .dateTime{
        color: #666666;
    }
    .coupon-block{
        width: 600px;
        text-align: center;
        margin: 0 auto 30px;
    }
    .coupon-mask{
        background: rgba(0,0,0, 0.4);
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .coupon-mask>div{
        width: 632px;
        height: 323px;
        background: #ffffff;
        position: absolute;
        top:50%;
        left: 50%;
        z-index: 30;
        transform: translate(-50%,-50%);
    }
    .coupon-mask>div>img{
        position: absolute;
        top:15px;
        right:15px;
        cursor: pointer;
    }
    .coupon-mask>div>div{
        position: absolute;
        top:78px;
        left: 44px;
    }
    .mask1{
        position: absolute;
        top:26px;
        left: 274px;
        font-size: 20px;
        color: #EB5E00;
    }
    .coupon-mask>div>div>div{
        font-size: 18px;
        margin-bottom: 20px;
    }
</style>
<style>
    .el-tabs__nav{
         padding-left: 9px;
    }
    .el-tabs__active-bar{
        margin-left: 9px;
        background-color: #333333;
    }
    .el-tabs__item.is-active {
        color: #353535;
    }
    .el-tabs__item{
        height: 39px;
        font-size: 14px;
        color: #666666;
        font-family: "PingFangSC-Regular";
    }
    .el-tabs__item:hover{
        color:#666666;
    }
</style>

